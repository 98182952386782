import * as React from "react"
import Header from './header'
import Footer from './footer'

import 'normalize.css'
import './layout.sass'

export default function Layout({ children, forms, copyright, user, cookies, footer, header, socials, locale, uri }) {
  return (
    <>
      <Header header={header} forms={forms} locale={locale} socials={socials} uri={uri} />
      <main id="main">
        <div className="container">{children}</div>
      </main>
      <Footer footer={footer} forms={forms} locale={locale} user={user} cookies={cookies} copyright={copyright} socials={socials} uri={uri} />
    </>
  )
}
