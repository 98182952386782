import React, { useRef } from "react";
import { Link } from "gatsby";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

// Icons
import Chevron from "../images/icons/chevron.svg";
import Home from "../images/icons/home_slim.svg";

export default function MenuDrop({ header, locale }) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  let content;

  for (let i = 0; i < header.length; i++) {
    if (header[i].locale.locale === locale) {
      content = header[i].mainHeader.headerSubmenu;
    }
  }

  return (
    <div
      ref={dropdownRef}
      className={`iconBox greenStyle ${isActive ? "visible" : ""}`}
    >
      <div onClick={onClick} className="iconBox-trigger">
        <Home />
        <Chevron />
      </div>
      <div className="iconBox-content">
        <ul className="iconBox-middle">
          {content.dropdownPages.map((value, i) => (
            <li key={i}>
              <Link
                to={value.pageLink}
                activeClassName="active"
              >
                {value.pageTitle}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
