import * as React from "react";
import { Link } from "gatsby";
import Developed from '../images/flat.show.svg'

import "./footer.sass";

import UserAgreement from "../components/userAgreement";
import CookiePolicy from "../components/cookiePolicy";
import FeedBack from "../components/feedback";

export default function Footer({ footer, forms, copyright, user, cookies, socials, locale, uri }) {
  let content;
  let copy;
  let userAgr;
  let policy;

  for (let i = 0; i < footer.length; i++) {
    if (footer[i].locale.locale === locale) {
      content = footer[i].mainFooter;
    }
  }

  for (let i = 0; i < copyright.length; i++) {
    if (copyright[i].locale.locale === locale) {
      copy = copyright[i].blockCopyrights;
    }
  }

  for (let i = 0; i < user.length; i++) {
    if (user[i].locale.locale === locale) {
      userAgr = user[i].blockUserAgreement;
    }
  }

  for (let i = 0; i < cookies.length; i++) {
    if (cookies[i].locale.locale === locale) {
      policy = cookies[i].blockUserAgreement;
    }
  }

  return (
    <footer id="footer">
      <div className="container">
        <strong className="footerLogo">
          <Link to={`/${locale === 'uk' ? locale.replace("uk", "") : locale.replace("uk", "") + "/"}`} dangerouslySetInnerHTML={{ __html: content.footerLogo }}></Link>
        </strong>
        <div className="middle">
          <nav id="menu">
            <ul>
              <li>
                <ul>
                  {content.leftColumnItems.map((value, i) => (
                    <li key={i}>
                      <Link to={value.item} activeClassName="active">
                        {value.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <ul>
                  {content.centrColumnItems.map((value, i) => (
                    <li key={i}>
                      <Link to={value.item} activeClassName="active">
                        {value.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
                {content.rightColumnItems.map((value, i) => (
                  <li key={i}>
                    <Link to={value.item} activeClassName="active">
                      {value.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
          <div className="alignRight">
            <FeedBack
              classColor="lighter"
              buttonIcon={content.orderButtonIcon}
              buttonText={content.orderButtonText}
              forms={forms}
            />
            <div className="social">
              {socials.socialNetworks.map((value, i) => (
                <a
                  key={i}
                  rel="noreferrer"
                  target="_blank"
                  href={value.link}
                  aria-label={value.title}
                  dangerouslySetInnerHTML={{ __html: value.icon }}
                >
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="container">
          <div className="copyright">
            {copy.copyrightTextBefore}
            <UserAgreement linkText={copy.userAgreement.linkText} popupContent={userAgr} />
            {copy.copyrightTextMiddle}
            <CookiePolicy linkText={copy.cookiesPolicy.linkText} popupContent={policy} />
          </div>
          <a className="developed" target="_blank" rel="noopener noreferrer" href="https://www.flatshow.org/"><span>developed by</span><img src={Developed} alt="flat.show" /></a>
        </div>
      </div>
    </footer>
  );
}