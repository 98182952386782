import React, { useRef } from "react";
import { Link } from "gatsby";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

import "./nav.sass";

// Icons
import Menu from "../images/icons/menu.svg";
import Close from "../images/icons/close.svg";
import FeedBack from "../components/feedback";

// Patterns
import Pattern from "../images/pattern_04.png";

export default function Nav({ header, forms, socials, locale, uri }) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  let content;

  for (let i = 0; i < header.length; i++) {
    if (header[i].locale.locale === locale) {
      content = header[i].mainHeader;
    }
  }

  return (
    <div ref={dropdownRef} className={`navBox ${isActive ? "visible" : ""}`}>
      <div className="navBox-top">
        <div className="social">
          {socials.socialNetworks.map((value, i) => (
            <a
              key={i}
              rel="noreferrer"
              target="_blank"
              href={value.link}
              aria-label={value.title}
              dangerouslySetInnerHTML={{ __html: value.icon }}
            ></a>
          ))}
        </div>
        <div className="menuIcon" onClick={onClick}>
          <Menu />
          <Close />
        </div>
      </div>
      <div className="navBox-content">
        <div className="navBox-middle">
          <div className="pattern">
            <img src={Pattern} alt="Pattern" />
          </div>
          <nav className="navBox-menu">
            <ul>
              {content.headerMenuFix.map((value, i) => (
                <li key={i}>
                  <Link to={value.pageLink} activeClassName="active">
                    {value.pageTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="navBox-buttons">
            <Link className="button iconButton" to={content.menuOrderHouseBtn.link}>
              <div
                className="button-icon"
                dangerouslySetInnerHTML={{
                  __html: content.menuOrderHouseBtn.icon,
                }}
              ></div>
              {content.menuOrderHouseBtn.text}
            </Link>
            <FeedBack
              classColor="orange"
              buttonIcon={content.menuCallbackBtn.icon}
              buttonText={content.menuCallbackBtn.text}
              forms={forms}
            />
          </div>
          <ul className="language">
            <li>
              <Link activeClassName="active" to={uri.replace("/ru", "").replace("/en", "").replace("/main", "")}>UA</Link>
            </li>
            <li>
              <Link activeClassName="active" to={`/ru${uri.replace("/ru", "").replace("/en", "").replace("/main", "")}`}>RU</Link>
            </li>
            {/* <li>
              <Link activeClassName="active" to={`/en${uri}`}>EN</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
