import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Nav from "./nav";
import MsgDrop from "./msgDrop";
import MenuDrop from "./menuDrop";

import "./header.sass";

export default function Header({ header, forms, socials, locale, uri }) {
  let content;

  for (let i = 0; i < header.length; i++) {
    if (header[i].locale.locale === locale) {
      content = header[i].mainHeader;
    }
  }

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 120);
    });
  }, []);

  return (
    <header id="header" className={scroll ? "fixed" : ""}>
      <div className="container">
        <Nav
          header={header}
          forms={forms}
          socials={socials}
          locale={locale}
          uri={uri}
        />
        <strong className="logo">
          <Link
            to={`/${
              locale === "uk"
                ? locale.replace("uk", "")
                : locale.replace("uk", "") + "/"
            }`}
            dangerouslySetInnerHTML={{ __html: content.headerLogo }}
          ></Link>
        </strong>
        <div className="selectControls">
          <MenuDrop header={header} locale={locale} />
          <MsgDrop header={header} locale={locale} />
        </div>
      </div>
    </header>
  );
}
