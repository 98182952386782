import React, { useRef } from "react";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";

import Close from "../images/icons/close.svg";

export default function Review({ linkText, popupContent }) {
  const popupRef = useRef();
  const [isActive, setIsActive] = useDetectOutsideClick(popupRef, false);
  const onClick = () => setIsActive(!isActive);

  return (
    <>
      <div
        ref={popupRef}
        className={`modalWrapper textStyle ${isActive ? "display" : ""}`}
      >
        <div className="modalContent">
          <span className="modalClose" onClick={onClick}>
            <Close />
          </span>
          <h2>{popupContent.userAgreementTitle}</h2>
          <div className="scrollContent">
            <div
              className="textContent"
              dangerouslySetInnerHTML={{
                __html: popupContent.userAgreementText,
              }}
            ></div>
          </div>
          <div className="alignCenter">
            <button onClick={onClick} className="button lighter minWidth">
              {popupContent.userAgreementButtonText}
            </button>
          </div>
        </div>
      </div>
      <button onClick={onClick}>{linkText}</button>
    </>
  );
}
