import React, { useRef } from "react";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

// Icons
import Chevron from "../images/icons/chevron.svg";
import Phone from "../images/icons/phone_slim.svg";
import Viber from "../images/icons/viber.svg";
import Telegram from "../images/icons/telegram.svg";

export default function MsgDrop({ header, locale }) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  let content;

  for (let i = 0; i < header.length; i++) {
    if (header[i].locale.locale === locale) {
      content = header[i].mainHeader.headerMessengerMenu;
    }
  }

  return (
    <div ref={dropdownRef} className={`iconBox ${isActive ? "visible" : ""}`}>
      <div onClick={onClick} className="iconBox-trigger">
        <Phone />
        <Chevron />
      </div>
      <div className="iconBox-content">
        <ul className="iconBox-middle">
          {content.messengerList.map((value, i) => (
            <li>   
              <a
                href={value.link}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{
                  __html: value.icon,
                }}
              ></a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
